var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-8 col-12"},[(_vm.tasksLoading)?_c('div',[_c('loader',{attrs:{"loading":_vm.tasksLoading}})],1):_c('div',{staticClass:"pb-4"},[_c('div',{staticClass:"row"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"col-lg-6 col-md-6 col-12 mb-4"},[_c('div',{staticClass:"company-list card border-0 rounded shadow bg-white d-flex align-items-end flex-column mb-3"},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"mx-auto d-block mb-3 img-fluid",attrs:{"src":item.taskImage,"alt":""}}),_c('a',{staticClass:"text-dark h5 name",attrs:{"href":"#"},on:{"click":function($event){_vm.$router.push({
                  name: 'user.surveys',
                  params: {
                    name: _vm.convertToKebabCase(item.name),
                    id: item.id,
                    questionIds: item.questionIds,
                    code: item.code,
                  },
                })}}},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"text-muted mt-1 mb-0",domProps:{"innerHTML":_vm._s(item.description)}})]),_c('div',{staticClass:"mt-auto border-top"},[_c('div',{staticClass:"px-4 pt-4"},[(!item.isCelebration)?[(item.taskType == 'ProfileTask')?[_c('a',{staticClass:"btn btn-block btn-soft-primary",attrs:{"href":"#"},on:{"click":function($event){_vm.$router.push({
                        name: 'user.surveys',
                        params: {
                          name: _vm.convertToKebabCase(item.name),
                          id: item.id,
                          questionIds: item.questionIds,
                          code: item.code,
                        },
                      })}}},[_vm._v("Göreve katılmak için tıklayın. "),_c('i',{staticClass:"fea icon-sm",attrs:{"data-feather":"arrow-right"}})]),_c('span',{staticClass:"float-right text-muted small mt-2"},[_vm._v(" "+_vm._s(item.date)+" - ID: "+_vm._s(item.id)+" ")])]:(item.taskType == 'CRMCampaign')?[_c('a',{staticClass:"btn btn-block btn-soft-primary",attrs:{"href":"#"},on:{"click":function($event){return _vm.$router.push({
                        name: 'user.campaign',
                        params: { id: item.id },
                      })}}},[_vm._v("Göreve katılmak için tıklayın. "),_c('i',{staticClass:"fea icon-sm",attrs:{"data-feather":"arrow-right"}})]),_c('span',{staticClass:"float-right text-muted small mt-2"},[_vm._v(" "+_vm._s(item.date)+" - ID: "+_vm._s(item.id)+" ")])]:(item.taskType == 'ExternalSurvey')?[_c('a',{staticClass:"btn btn-block btn-soft-primary",attrs:{"href":"#"},on:{"click":function($event){return _vm.redirectTo(item)}}},[_vm._v("Göreve katılmak için tıklayın. "),_c('i',{staticClass:"fea icon-sm",attrs:{"data-feather":"arrow-right"}})]),_c('span',{staticClass:"float-right text-muted small mt-2"},[_vm._v(" "+_vm._s(item.date)+" - ID: "+_vm._s(item.id)+" ")])]:(item.taskType == 'LiveSurvey')?[_c('a',{staticClass:"btn btn-block btn-soft-primary",attrs:{"href":"#"},on:{"click":function($event){return _vm.redirectTo(item, 'live')}}},[_vm._v("Göreve katılmak için tıklayın. "),_c('i',{staticClass:"fea icon-sm",attrs:{"data-feather":"arrow-right"}})]),_c('span',{staticClass:"float-right text-muted small mt-2"},[_vm._v(" "+_vm._s(item.date)+" - ID: "+_vm._s(item.id)+" ")])]:(item.taskType == 'CrmMiniAnket')?[_c('a',{staticClass:"btn btn-block btn-soft-primary",attrs:{"href":item.urlLink}},[_vm._v(" Göreve katılmak için tıklayın. "),_c('i',{staticClass:"fea icon-sm",attrs:{"data-feather":"arrow-right"}})]),_c('span',{staticClass:"float-right text-muted small mt-2"},[_vm._v(" "+_vm._s(item.date)+" - ID: "+_vm._s(item.id)+" ")])]:[_c('a',{staticClass:"btn btn-block btn-soft-primary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return false;}}},[_vm._v("Göreve katılmak için tıklayın. "),_c('i',{staticClass:"fea icon-sm",attrs:{"data-feather":"arrow-right"}})]),_c('span',{staticClass:"float-right text-muted small mt-2"},[_vm._v(" "+_vm._s(item.date)+" - ID: "+_vm._s(item.id)+" ")])]]:_vm._e()],2)])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }